.shop-hero-stars-item {
    clip-path: polygon(
                    0% 5px,
              /* верхняя левая */ 5px 0%,
              /* верхняя левая */ calc(100% - 5px) 0%,
              /* верхняя правая */ 100% 5px,
              /* верхняя правая */ 100% calc(100% - 5px),
              /* нижняя правая */ calc(100% - 5px) 100%,
              /* нижняя правая */ 5px 100%,
              /* нижняя левая */ 0 calc(100% - 5px) /* нижняя левая */
    );
    background-color: rgba(23, 16, 39, 0.7);
    padding: 5px 5px 5px 8px;
    width: 49%;
    max-height: 85px;
    margin-bottom: 8px;
  }
  
  .shop-hero-stars-item-name {
    font-size: 18px;
    color: rgba(178, 142, 76, 1);
  
    @media (max-width: 384px) {
        font-size: 15px;
    }
  }
  
  .shop-hero-stars-item-img {
    border-radius: 4px;
    filter: blur(4px);
  }
  
  .shop-hero-stars-item-click-details {
    color: rgba(176, 176, 176, 1);
    font-size: 10px;
    margin-top: 8px;
  
    @media (max-width: 384px) {
      text-align: start !important;
    }
  }
  
  .shop-hero-stars-item-price {
    font-size: 18px;
    margin-top: 10px;
    color: #ffffff;
    line-height: 8px;
  
    @media (max-width: 384px) {
      font-size: 14px;
    }
  }
  .sshop-hero-stars-item-price-red {
    color: rgb(177, 61, 88);
  }