.ton-promo-sections-nfts-items-item {
    height: fit-content; 
    min-width: 32%;
    width: 32%;   
    max-width: 32%; 
    border-radius: 4px;
    background-color: rgb(0, 0, 0, 50%);
    padding-bottom: 13px;
}

.ton-promo-sections-nfts-items-item-name {
    color: #FFFFFF;
    font-size: 12px; 
    padding-left: 4px;
    padding-right: 4px;  
    padding-top: 4px;
    
    opacity: 30%;
}

.ton-promo-sections-nfts-items-item-price,
.ton-promo-sections-nfts-items-item-owner {
    font-size: 12px;
    color: #B0B0B0;
    width: 70px;
    
    opacity: 30%;
}