.health_bar {
    bottom: 115px;
}

.coin-container {
    padding: 8px;
}

.canvas {
    top: 15%;
}

.gamescreen-coin {
    width: 21px;
}

.checkbox {
    top: 80px;
    right: 10px;
    width: 70px;
    font-size: 12px;
    color: white;
    overflow-wrap: break-word;
}

.money {
    font-size: 32px;
    font-weight: 700;
    color: #DC9F44;
    margin-left: 12px;
    text-shadow: rgba(0, 0, 0, 0.5) 4px 4px 4px;
    animation: money 0.5s linear ;
}

@keyframes money {
    0% {
    }

    50% {
        transform: scale(1.1);
    }

    100% {
    }
}

.event {
    top: 30%;
}

.lighting-1 {
    width: 20px;
    height: 20px;
    top: 22%;
    left: 12%;
    opacity: 90%;
    border-radius: 50%;
    background-color: #AAC7C2;
    animation: light 0.8s infinite;
}

.lighting-2 {
    width: 15px;
    height: 15px;
    top: 29%;
    left: 34%;
    opacity: 80%;
    border-radius: 50%;
    background-color: beige;
    animation: light 1s infinite;
}

.lighting-3 {
    width: 10px;
    height: 10px;
    top: 26.5%;
    right: 11.5%;
    opacity: 80%;
    border-radius: 50%;
    background-color: antiquewhite;
    animation: light 0.9s infinite;
}

@keyframes light {
    0% {
        filter: blur(5px);
        transform: scale(1)
    }
    50% {
        filter: blur(7px);
        transform: scale(1.1);
    }
    100% {
        filter: blur(5px);
        transform: scale(1)
    }
}