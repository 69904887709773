.navbar-text {
  color: #B8B8B8 !important;
}

.navbar-text,
.navbar-text-active {
  font-size: 12px;
  font-style: normal;
}

.navbar-text-active {
  color: #B28E4C !important;
}

.info {
  position: relative;
  bottom: 36px;
  left: 30px;
  text-decoration: none;
  width: 18px;
  height: 18px;
}

.text {
  position: relative;
  width: 19px;
  height: 19px;
  display: flex;
  justify-content: center;
  bottom: 49px;
  left: 28px;
  font-size: 12px;
}

.quest-count {
  width: 16px;
  height: 16px;

  background-color: #B13D58;

  font-size: 10px;
  line-height: 8px;
  color: #FFFFFF;

  position: relative;
  bottom: 36px;
  left: 30px;
  text-decoration: none;
}