.ton-promo-sections-exchange-access {
    height: 60px;
    background-color: rgb(0, 0, 0, 50%);    
    border-radius: 4px;

    padding-left: 10px;
    padding-right: 24px;
    padding-top: 15px;
    padding-bottom: 11px;

    @media (max-width: 360px) {
        padding-right : 10px;
    }
}

.ton-promo-sections-exchange-title {
    font-size: 14px;
    color: #B0B0B0;   
    line-height: 8px; 
    margin-bottom: 10px;
}

.ton-promo-sections-exchange-desc {
    color: #B0B0B0;
    max-width: 187px;
    font-size: 8px;
    line-height: 8px;    
}

.ton-promo-sections-exchange-allowed {
    color: #218D45;
    font-size: 16px;
    height: 30px;
    line-height: 8px;
}

.ton-promo-sections-exchange-not-allowed {
    color: #B13D58;
    font-size: 16px;
    height: 30px;
    line-height: 8px;
}