.mines-modal-container {
  padding: 8px;
  width: 280px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
}

.mines-modal-img-container {
  gap: 6px;
}

.mines-modal-text-container {
  margin-top: 8px;
}

.mines-modal-add-reward {
  background-color: rgb(76, 54, 60, 70%);
  padding: 0px 10px;
  border-radius: 4px;
  font-size: 10px;
  line-height: 10px;
  bottom: 2px !important;
  right: 2px !important;
}