@import '../../../scss/global.scss';

.ritual-stone-open {
    //background-color: $main-bg-block;
    border-radius: $main-border-radius-block;
    padding: 8px;
    padding-top: 10px;
    padding-bottom: 17px;
}

.ritual-stone-open-cell {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
    width: 86px;
    height: 86px;
    min-width: 86px;
    min-height: 86px;
    margin: 8px;
    overflow: hidden;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
}

.ritual-stone-open-button {
    border: 1px solid rgba(76, 54, 60);
    width: 76px;
    height: 76px;
    min-width: 76px;
    min-height: 76px;
  }

.ritual-stone-container-go {
    margin-top: 18px;
}

.ritual-stone-container-go_text {
    font-size: 16px;
    line-height: 16px;
    text-decoration: none;
    color: #B0B0B0;
}

.ritual-stone-container-count-fragments {
  background-color: rgb(76, 54, 60, 70%);
  padding: 0px 10px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 14px;
  bottom: 2px !important;
  right: 2px !important;
}

@keyframes pulse {
    0% {
      transform: scale(1);
    }
   
    
    100%{
      transform: scale(1.4);
    }
  }
  
  #dot {
    transform-origin: 50% 50%;
    animation: pulse .6s -.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) alternate infinite;
  }