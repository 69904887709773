@import '../../scss/global.scss';

.battles-container {
    padding: 8px;
    padding-top: 10px;
    padding-bottom: 17px;
}

.battles-container-info {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
    padding: 8px 16px 16px 16px;
}

.battles-container-rules {
    color: #B0B0B0;
    font-size: 12px;
    line-height: 12px;
}

.battles-container-rules-title {
    color: #FFFFFF;
    font-size: 16px;
    line-height: 32px;
}

.battles-container-button {
    margin-top: 16px;
}
.battles-container-button-text {
    color: #B0B0B0;
    font-size: 12px;
}