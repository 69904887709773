@import '../../scss/global.scss';

.refferal-friends-container {

    background-color: $main-bg-block;
    
    border-radius: 0px;

    padding: 8px;
    padding-top: 10px;
}

.refferal-friends-container-header {
    padding-left: 8px;
    padding-right: 8px;
    background-color: $main-bg-block;
}

.refferal-friends-container-title {
    font-size: 12px;
    line-height: 14px;
    color: #B0B0B0;
}

.refferal-friends-container-reload-await {
    opacity: 30%;
}

.refferal-friends-container-reload-text {
    color: white;
    font-size: 11px;
}

.refferal-friends-container-friends {
    overflow: scroll;
}