.damage {
    color: #B0B0B0;
    font-size: 22px;
    font-weight: 700;
    animation: damage 1.5s linear;
  }
  
  @keyframes damage {
    0% {
      transform: scale(1);
      opacity: 0.5;
    }
  
    70% {
      transform: scale(2);
      top:170px;
      opacity: 0.3;
    }
  
    100% {
      transform: scale(1);
      opacity: 0;
      top: 50px;
    }
  }