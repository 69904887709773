@import '../../scss/global.scss';

.rating-container {
    background-color: $main-bg-block;
    
    border-radius: $main-border-radius-block;

    padding: 8px;
    padding-top: 10px;
    padding-bottom: 17px;
}

.rating-container-title {
    font-size: 16px;
    color: #FFFFFF;
    line-height: 19px;

    margin-left: 9px;
}

.rating-container-reload-await {
    opacity: 30%;
}

.rating-container-navbar {
    margin-top: 18px;
}

.rating-container-navbar-item {
   padding-left: 24px;
   padding-right: 24px;

   font-size: 14px;
   color: #B0B0B0;
}

.rating-container-navbar-item-active {
    background-color: rgb(0, 0, 0, 50%);
    border-radius: 4px;
}

.rating-container-items-container {
    margin-top: 18px;
}

.rating-container-user {
    min-height: 40px;
    height: 40px;
    background-color: rgb(178, 142, 76, 40%);

    border-radius: 4px;
    padding: 5px 8px;
}

.rating-container-user-place {
    line-height: 10px;
    font-size: 16px;
    color: 171027;
    margin-right: 9px;
}

.rating-container-user-name {
    line-height: 10px;
    font-size: 12px;
    color: #B0B0B0;
}

.rating-container-user-count {
    font-size: 12px;
    color: #FFFFFF;
    line-height: 10px;
}