.ton-promo-sections-frens-info-reward {
    height: 60px;
    background-color: rgb(0, 0, 0, 50%);   
    border-radius: 4px; 
    margin-right: 4px;

    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
}

.ton-promo-sections-frens-info-reward-title {
    color: #B0B0B0;
    line-height: 8px;
    font-size: 14px; 
    
    @media (max-width: 360px) {
        font-size: 12px;
    }
}

.ton-promo-sections-frens-info-reward-count {
    font-size: 24px;
    line-height: 24px;
    color: #B28E4C;  
    
    @media (max-width: 360px) {
        font-size: 20px;
        line-height: 20px;
    }
}

.ton-promo-sections-frens-info-reward-desc {
    line-height: 8px;
    font-size: 8px;
    color: #B0B0B0;
}