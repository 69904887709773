.ton-promo-sections-frens-info-timer {
    height: 60px;
    width: 104px;
    max-width: 104px;
    min-width: 104px;
    background-color: rgb(0, 0, 0, 50%);   
    border-radius: 4px; 
    margin-left: 4px;

    padding-right: 8px;
    padding-left: 8px;
    padding-top: 6px;
}

.ton-promo-sections-frens-info-timer-title {
   font-size: 10px;
   line-height: 8px;
   color: #B0B0B0; 
}

.ton-promo-sections-frens-info-timer-time {
    font-size: 24px;    
    color:#B0B0B0
}