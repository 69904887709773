.event-container-modal {
  background-color: rgba(23, 16, 39, 0.7);
  border-radius: 4px;
  padding: 8px;
  margin-left: 16px;
  margin-right: 16px;
  height: 80%;
  max-height: 80%;
}

.event-container-modal-desc {
  font-size: 12px;
  line-height: 12px;
  color: #B0B0B0;
  margin-top: 8px;
  margin-bottom: 16px;
}

.event-container-modal-reward {
  color: #B0B0B0;
  font-size: 12px;
}

.event-container-modal-reward-item {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 8px;
  border-radius: 4px;
}

.event-container-modal-reward-grey {
  background-color: rgba(176, 176, 176, 0.1);
  border-radius: 4px;
  padding: 0 4px;
}

.event-container-modal-reward-no {
  padding: 0 4px;
}


.event-container-modal-reward-table {
  margin-top: 8px;
}

.event-container-modal-reward-text {
  text-align: left;
  width: 90px;
}

.event-container-modal-reward-hero {
  border: 1px solid rgba(76, 54, 60, 1);
  padding: 2px;
  width: 64px;
  height: 64px;
}

.event-container-modal-reward-hero-main {
  height: 60px;
}

.event-container-modal-reward-image  {
  gap: 8px;
}

.event-container-modal-reward-name {
  padding-left: 8px;
  margin-top: 4px;
}