.small-chest-container {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 8px;
  border-radius: 4px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.small-chest-info {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 8px 17px 35px 9px;
  border-radius: 4px;
  width: 208px;
  height: 94px;
}

.small-chest-title {
  font-size: 14px;
  margin-bottom: 7px;
}

.small-chest-text {
  font-size: 11px;
  color: rgba(176, 176, 176, 1);
}

.info-logo {
  top: -6px;
  right: -2.45px;
  opacity: 0.7;
}

.small-chest-img {
  right: 4px;

  @media (max-width: 360px) {
    bottom: 8px !important;
  }
}

.small-chest-img img {
  @media (max-width: 360px) {
    height: 95px;
  }
}