@import '../../scss/global.scss';

.offcanvas_img {
  width: 120px !important;
  height: 120px !important;
}

.hero-offcanvas-name {
  font-size: 24px;
  color: #B28E4C;
  line-height: 24px;
}
.hero-offcanvas-description {
  font-size: 12px;
  line-height: 12px;
  color: #b0b0b0;
  margin-bottom: 7px;
}
.hero-offcanvas-race,
.hero-offcanvas-alliance,
.hero-offcanvas-fragments {
  line-height: 18px;
  color: #B28E4C;
  font-size: 15px;
}
.hero-offcanvas-race-value,
.hero-offcanvas-alliance-value,
.hero-offcanvas-fragments-value {
  font-size: 15px;
  color: #b0b0b0;
}

.hero-offcanvas-fragments-green {
  font-size: 15px;
  color: #218D45;
}

.hero-offcanvas-level {
  color: #b0b0b0;
  font-size: 16px;
}

.hero-offcanvas-damage,
.hero-offcanvas-prestige {
  opacity: 20%;
}

.hero-offcanvas-damage,
.hero-offcanvas-rank,
.hero-offcanvas-prestige {
  color: #b0b0b0;
  font-size: 14px;
}


.offcanvas_cont {
  background-color: $main-bg-offcanvas;
  height: 100%;
}

.offcanvas_img {
  width: 35vw;
}

.offcanvas_indicators_box {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  padding: 8px;
  margin-top: 10px;
  border-radius: $main-border-radius-block;
}

.priceup_text_offcanvas {
  align-items: center;
  position: absolute;
  top: 0;
  left: 30%;

  h5 {
    font-size: 6vw;
  }
  h6 {
    font-size: 5vw;
    color: #b0b0b0;
  }
}

.hero-offcanvas-abilities {
  border-radius: 4px;

  padding-top: 5px;
  padding-bottom: 5px;

  
  background-color: rgb(0, 0, 0, 30%);
}

.hero-offcanvas-padding {
  padding: 0 22px;
  padding-top: 15px;
  padding-bottom: 15px;  
}

.hero-offcanvas-main-container {
  height: calc(100% - 31px) !important;
  backdrop-filter:  blur(5px);
}

.hero-offcanvas-income-crystal {
  font-size: 14px;
  color: #B0B0B0;  
  margin-top: 7px;
}

.hero-offcanvas-alliance-info {
  width: 16px;
  height: 16px;
  background-color: #B28E4C;
  color: white;
  font-size: 10px;
  border-radius: 50%;
  margin-left: 6px;
}

.hero-offcanvas-alliance-info-content {
  padding-bottom: 2px;
  width: 210px;
  padding-left: 10px;
  z-index: 9999; 
  background: rgb(0,0,0, .9);
  border-radius: 4px;
  margin-top: 5px;
  height: auto;
}

.hero-offcanvas-alliance-info-content-name {
  font-size: 14px;
  line-height: 14px;
  color: white;
  margin-bottom: 2px;
}

.hero-offcanvas-alliance-info-content-heroes {
  font-size: 12px;
  line-height: 12px;
  color: #B0B0B0;
  margin-bottom: 7px;
}

.hero-offcanvas-alliance-info-content-bonus {
  font-size: 12px;
  line-height: 12px;
  color: #B0B0B0;
}