.ton-promo-sections-frens-rating-item {
    height: 40px;
    background-color: rgb(0, 0, 0, 60%);
    border-radius: 4px;
    margin-bottom: 5px;
    padding: 5px 8px;
}

.ton-promo-sections-frens-rating-item-user {
    font-size: 12px;
    line-height: 10px;
    color: #B0B0B0;
}