.all-daily-reward-item {
    min-width: 80px;
    min-height: 100px;
    width: 80px;
    height: 100px;
}

.all-daily-reward-item-container {
    background-color: rgb(0, 0, 0, 50%);    
    border-radius: 6px;
    padding-top: 3px;
    padding-bottom: 5px;
    padding-right: 5px;
    padding-left: 5px;
}

.received-opacity-25 {
    opacity: 25%;    
}

// .all-daily-reward-item-container-received {
//     opacity: 25%;
// }

.all-daily-reward-item-day {
    font-size: 14px;
    color: #B0B0B0;
    line-height: 13px;
}

.all-daily-reward-item-amount {
    font-size: 14px;
    color: #FFFFFF;
    background-color: rgb(176, 176, 176, 30%);
    width: 100%;
    height: 20px;
    border-radius: 6px;
}

.all-daily-reward-item-image {
    // width: 56px;
    // height: 50px;
    margin-bottom: 4px;
}