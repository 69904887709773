.clan-container-myclan-noclan-info {
    font-size: 16px;
    line-height: 14px;
    color: #B0B0B0;
}

.clan-container-myclan-noclan-button {
    font-size: 14px;
    margin-top: 14px;
}

.clan-container-myclan-noclan-button_text {
    color: #B0B0B0;
}