.shop-hero-stars-item-modal {
    height: 156px;
    background-color: rgb(23, 16, 39, 90%);
    border-radius: 4px;
    overflow: hidden;
  }
  
  .shop-hero-stars-item-modal-image {
    width: 225px;
    height: 225px;
    filter: blur(5px);
  }
  
  .shop-hero-stars-item-modal-buy-image {
    filter: none;
  }
  
  .shop-hero-stars-item-modal-title {
    font-size: 18px;
    color: #b0b0b0;
  }
  
  .shop-hero-stars-item-modal-name {
    font-size: 22px;
    color: #b28e4c;
    line-height: 22px;
  }
  
  .shop-hero-stars-item-modal-btn {
    font-size: 16px;
    color: #b0b0b0;
  }
  
  .shop-hero-stars-item-modal-info {
    color: #fff;
    font-size: 18px;
    line-height: 18px;
    text-align: end;
  }
  
  .shop-hero-stars-item-modal-info span {
    color: #b28e4c;
  }
  
  .shop-hero-stars-item-modal-price {
    color: #fff;
    font-size: 16px;
    line-height: 19px;
  }
  
  .shop-hero-stars-item-modal-price-red {
    color: rgb(177, 61, 88);
  }
  
  .shop-hero-stars-item-modal-price-animate {
    animation: pulse 0.3s linear infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(2px);
    }
  
    50% {
      transform: translateX(-2px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  .shop-hero-stars-item-modal-buy-title {
    color: #B0B0B0;
    font-size: 16px; 
    line-height: 14px; 
    
    @media (max-width: 384px) {
      font-size: 14px;
    }
  }
  
  .shop-hero-stars-item-modal-buy-name {
    color: #B28E4C;
    font-size: 24px;  
    text-align: center;
  }
  
  .shop-hero-stars-item-modal-buy-continue {
    color: #B0B0B0;
    font-size: 16px;  
  }