.mini-navbar-config-game {
    min-width: 200px;
    height: auto;
    top: 40px;
    right: 0;
    filter: blur(0) !important;
    gap: 1px;
}

.mini-navbar-config-game-item {
    background-color: rgba(23, 16, 39, 0.7);
    border-radius: 4px;
    padding: 9px 30px 9px 9px;
}

.mini-navbar-config-game-item-title {
    color: #FFFFFF;
    font-size: 14px;
}

.mini-navbar-config-game-value {
    color: #B0B0B0;
    font-size: 16px;
    right: 15px;
}

.react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
}

.react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 25px;
    height: 16px;
    background: rgba(176, 176, 176, 1);
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
}

.react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    border-radius: 45px;
    transition: 0.2s;
    background: rgba(23, 16, 39, 0.7);
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
    width: 15px;
}