.skip-time-modal {
    width: 300px;
    height: 115px;
    background-color: rgb(0, 0, 0, .7);
    border-radius: 4px;
}

.skip-time-modal-svg {
    padding-top: 20px;
    padding-left: 10px;
}

.skip-time-modal-title {
    padding-top: 10px;
    color: #B28E4C;
    font-size: 20px;
    line-height: 15px;
    padding-left: 60px;
}

.skip-time-modal-desc {
    font-size: 12px;
    line-height: 10px;
    color: #B0B0B0;
    padding-left: 60px;
    margin-top: 9px;
}

.skip-time-modal-btn {
    padding-top: 5px;
    padding-left: 40px;
}

.skip-time-modal-btn-price {
    margin-left: 3px;
    color: white;
    font-size: 20px;
    line-height: 10px;
}

.skip-time-modal-btn-time {
    font-size: 14px;
    color: #B0B0B0;
    line-height: 10px;
}

.skip-time-modal-btn-animate {
    animation: drag 0.3s linear infinite;
  }

@keyframes drag {
    0% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(2px);
    }
  
    50% {
      transform: translateX(-2px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  