.academy-container-stats-book-image {
    border: 1px solid rgba(76, 54, 60, 1);
    border-radius: 4px;
    padding: 2px;
    margin-bottom: 6px;
}

.academy-container-stats-book-info {
    top: -12px;
    right: -8px;
    opacity: 0.7;
  }

.academy-container-stats-energy-title {
    font-size: 12px;
    color: #FFFFFF;
}

.academy-container-stats-energy {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 4px;
}

.container-energy-bar {
    width: 140px;
}
  
.energy-bar {
    background: #171027;
    height: 22px;
    border-radius: 4px;
    border: 1px solid #B28E4C;
    padding: 2px;
}
  
.energy-bar-fill {
    background-color: #B0B0B0;
    border-radius: 2px;
    overflow: hidden;
    width: 12px;
    margin-left: 2px;
}

.energy-bar-fill:first-child {
    margin-left: 0px;
}

.academy-container-button {
    margin-top: 8px;
}
.academy-container-button-text {
    color: #B0B0B0;
    font-size: 10px;
}