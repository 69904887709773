.event-container-desc {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    height: 160px;
    min-height: 160px;
}

.event-container-desc-title {
    font-size: 18px;
    line-height: 18px;
    color: #B28E4C;
    padding-top: 18px;
    padding-left: 8px;
}

.event-container-desc-text {
    font-size: 12px;
    line-height: 12px;
    color: #B0B0B0;
    padding-top: 18px;
}

.event-container-desc-button_text {
    color: #B0B0B0;
}

.event-container-desc-image {
    height: 160px;
}

.event-container-desc-button-left {
    margin-left: 8px;
}

.event-container-desc-button-right {
    margin-right: 8px;
}

.event-container-desc-button-opacity {
    opacity: 50%;
}