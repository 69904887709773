.clan-container-myclan-profile-hat {
    display: flex;
    justify-content: center;
    width: auto;
    height: 140px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
}

.clan-container-myclan-profile-footer {
    display: flex;
    justify-content: center;
    width: auto;
    height: 60px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    margin-top: 4px;
}

.clan-container-myclan-profile-info {
    padding: 8px 5px;
}

.clan-container-myclan-profile-logo {
    margin-top: 4px;
    padding-left: 8px;
    padding-right: 8px;
}

.clan-container-myclan-profile-title {
    font-size: 24px;
    line-height: 20px;
    color: #B28E4C;
}

.clan-container-myclan-profile-description {
    font-size: 14px;
    line-height: 13px;
    color: #B0B0B0;
    margin-top: 8px;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
            line-clamp: 3;              лине-кламп: 3;  

    -webkit-box-orient: vertical;

    max-width: 100%;
    word-wrap: normal;
    word-break: break-word;
}

.clan-container-myclan-profile-stats {
    font-size: 16px;
    line-height: 16px;
    color: #B0B0B0;
    margin-left: 8px;
}

.clan-container-myclan-profile-stats-count {
    color: #FFFFFF;
    margin-left: 8px;
}

.clan-container-myclan-profile-button-science {
    font-size: 14px;
    margin-left: 2px;
}

.clan-container-myclan-profile-button_text_red {
    color: #B13D58;
}

.clan-container-myclan-profile-button_text_grey {
    color: #B0B0B0;
}

.clan-container-myclan-profile-bonus {
    margin-top: 4px;
}

.clan-container-myclan-profile-bonus-title {
    margin-top: 8px;
    font-size: 14px;
    line-height: 16px;
    color: #B28E4C;
}

.clan-container-myclan-profile-item {
    margin-left: 8px;
    margin-right: 8px;
} 

.clan-container-myclan-profile-item-icon {
    margin-bottom: 4px;
}

.clan-container-myclan-profile-item-title {
    margin-top: 4px;
    line-height: 14px;
    font-size: 14px;
    color: #B0B0B0;
    text-align: center;
    max-width: 48px;
}