@import '../../scss/global.scss';

.heroes-container {
    background: $main-bg-block;
    border-radius: $main-border-radius-block;
    padding: 8px;
}

.hero-header {
    display: flex;
    gap: 1vw;
    color: #B0B0B0;
    align-items: center;
    justify-content: center;
}

.hero-header-btn-container {
    width: 77px;
}

.hero-header-active-btn {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
}