.mail-container-allmail-message {
    margin-top: 4px;
    height: 40px;
    min-height: 40px;
}

.mail-container-allmail-message-envelope {
    margin-right: 8px;
}

.mail-container-allmail-message-body{
    border-radius: 4px;
    background-color: rgb(0, 0, 0, 0.5);
    padding: 1px 8px;
}

.mail-container-allmail-message-body-title {
    font-size: 14px;
    line-height: 14px;
    color: #ffffff;
}

.mail-container-allmail-message-body-short {
    font-size: 12px;
    line-height: 12px;
    color: #B0B0B0;
}