.border_box {
  // background-color: #b28e4c;
  // border: 1px solid #B28E4C;
  display: flex;
  align-items: center;
  justify-content: center;
  //   width: 103%;
  margin-bottom: 6px;
  height: 126px;
  background-size: cover;
  padding: 2px;
  background-color: rgba(0,0,0,0.5);
}

// .map-background {
//   background-image: url("../../../../public/img/background/active-hero-background.png");
//   background-size: cover;
//   opacity: 0.15;
//   background-position-x: -5px;
//   background-position-y: 50%;
//   padding: 2px;
//   height: 122px;
// }


.line_box_unlocked {
  // background: rgba(0, 0, 0);
  display: flex;
  position: relative;
  z-index: 1;
  padding: 3px 7px 12px 20px;

  p {
    color: #b0b0b0;
  }
}

.hero-lines-view-unlocked-name {
  font-size: 20px;
  color: #B28E4C;
}

.line_img {
  // width: 83px;
  // height: 83px;
  width: 93px;
  height: 93px;
}

.hero-lines-view-unlocked-level {
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 6px;
  margin-left: 17px;
}

.hero-lines-view-unlocked-level span{
  font-size: 18px;
  line-height: 13px;  
  color: #FFFFFF;
}

.hero-lines-view-unlocked-damage {
  opacity: 20%;
  margin-bottom: 2px;
}

.hero-lines-view-unlocked-damage,
.hero-lines-view-unlocked-rank {
  font-size: 12px;
  line-height: 12px;
  color: rgba(176, 176, 176, 1);
}

.priceup_button {
  bottom: -5px;

}

.hero-lines-view-unlocked-damage-container {
  width: 40px;
  margin-bottom: 5px;
}

@media (max-width: 384px) {
  .priceup_button {
    bottom: -12px;
  }
}

@media (max-width: 349.09px) {
  .border_box {
    height: auto;
  }  
}

@media (max-width: 340px) {
  .hero-lines-view-unlocked-info-cont {
    gap: 3px;
    flex-direction: column !important;
  }
  .priceup_button {

  }
  .hero-lines-view-unlocked-ability {
    display: none;
  }
}

.hero-lines-view-unlocked-har {
  white-space: nowrap;
}

.hero-lines-view-unlocked-logo-info {
  gap: 5px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 2px;
  height: 14px;
  margin-bottom: 1px;
}