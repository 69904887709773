.battles-monster-health-bar-img {
  z-index: 2;
}

.battles-monster-health-container {
  background: #171027;
  height: 27px;
  border-radius: 4px;
  border: 1px solid #b28e4c;
  margin-left: -15px;
  padding: 2px;
}

.battles-monster-health-fill {
  background-color: #b28e4c;
  border-radius: 2px;
  overflow: hidden;
}

.battles-monster-health-text {
  color: #fff;
  font-size: 16px;
  width: 80%;
  justify-content: center;
  display: flex;
  position: absolute;
}
