@import '../../scss/global.scss';

.user-info-offcanvas_img {
  width: 120px !important;
  height: 120px !important;
}

.hero-user-info-offcanvas-name {
  font-size: 24px;
  color: #B28E4C;
}

.hero-user-info-offcanvas-country,
.hero-user-info-offcanvas-rating,
.hero-user-info-offcanvas-clan,
.hero-user-info-offcanvas-clanrank,
.hero-user-info-offcanvas-dps,
.hero-user-info-offcanvas-ips {
  line-height: 18px;
  color: #b0b0b0;
  font-size: 15px;
}
.hero-user-info-offcanvas-country-value,
.hero-user-info-offcanvas-rating-value,
.hero-user-info-offcanvas-clan-value,
.hero-user-info-offcanvas-dps-value,
.hero-user-info-offcanvas-ips-value {
  font-size: 15px;
  color: #FFFFFF;
}
.hero-user-info-offcanvas-clanrank-value {
  line-height: 18px;
  color: #B28E4C;
  font-size: 15px;
}

.user-info-offcanvas_cont {
  background-color: $main-bg-offcanvas;
  height: 100%;
}

.user-info-offcanvas_img {
  width: 35vw;
}

.user-info-offcanvas_indicators_box {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  padding: 8px;
  margin-top: 8px;
  border-radius: $main-border-radius-block;
}

.hero-user-info-offcanvas-abilities {
  border-radius: 4px;

  padding-top: 5px;
  padding-bottom: 5px;

  
  background-color: rgb(0, 0, 0, 30%);
}

.hero-user-info-offcanvas-padding {
  padding: 0 22px;
  padding-top: 15px;
  padding-bottom: 15px;  
}

.hero-user-info-offcanvas-main-container {
  height: calc(100% - 31px) !important;
  backdrop-filter:  blur(5px);
}

.hero-user-info-offcanvas-abilities-user {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  padding: 8px;
  margin-top: 24px;
  border-radius: $main-border-radius-block;
}

.user-info-offcanvas_indicators_box-user {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  padding: 8px;
  margin-top: 24px;
  border-radius: $main-border-radius-block;
}

.hero-user-info-offcanvas-user-top {
  border: 1px solid rgba(76, 54, 60, 1);
  padding: 3px;
}

.hero-user-info-offcanvas-user-top-border {
  border: 1px solid #B28E4C;
  padding: 3px;
}

.hero-user-info-offcanvas-user-top-title {
  font-size: 16px;
  line-height: 10px;
  margin-left: 8px;
  margin-top: 8px;
  color: #B0B0B0; 
}

.hero-user-info-offcanvas-user-ipc {
  margin-top: 8px;
  margin-left: 8px;
  font-size: 14px;
  line-height: 10px;
  color: #B0B0B0;
}

.hero-user-info-offcanvas-user-ipc-top {
  margin-top: 8px;
  margin-left: 8px;
  font-size: 14px;
  line-height: 10px;
  color: #B28E4C;
}

.hero-user-info-offcanvas-user-top img {
  @media (max-width: 349.09px) {
    width: 80px;
    height: 80px;
  }
}

.hero-user-info-offcanvas-user-top-border img {
  @media (max-width: 349.09px) {
    width: 90px;
    height: 90px;
  }  
}