@import '../../scss/global.scss';

.clan-view-container {
    background-color: $main-bg-block;
    
    border-radius: $main-border-radius-block;

    padding: 8px;
    padding-top: 10px;
    padding-bottom: 17px;    
}

.clan-view-container-hat {
    display: flex;
    justify-content: center;
    width: auto;
    height: 140px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
}

.clan-view-container-logo {
    margin-top: 4px;
    padding-left: 8px;
    padding-right: 8px;
}

.clan-view-container-title {
    font-size: 24px;
    line-height: 20px;
    color: #B28E4C;
}

.clan-view-container-description {
    font-size: 14px;
    line-height: 13px;
    color: #B0B0B0;
    margin-top: 8px;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
            line-clamp: 3;              лине-кламп: 3;  

    -webkit-box-orient: vertical;

    max-width: 100%;
    word-wrap: normal;
    word-break: break-word;
}

.clan-view-container-stats {
    font-size: 16px;
    line-height: 16px;
    color: #B0B0B0;
    margin-left: 8px;
}

.clan-view-container-stats-count {
    color: #FFFFFF;
    margin-left: 8px;
}

.clan-view-container-button_text_green {
    color: #218D45; 
}

.clan-view-container-info {
    padding: 8px 5px;
}