.clan-container-allclans-item {
    margin-left: 12px;
    margin-right: 12px;
} 

.clan-container-allclans-item-icon {
    margin-bottom: 4px;
}

.clan-container-allclans-item-title {
    margin-top: 4px;
    line-height: 14px;
    font-size: 12px;
    color: #B0B0B0;
    text-align: center;
    max-width: 48px;
}

.clan-container-allclans-part{
    border-radius: 4px;
    height: 40px;
    background-color: rgb(0, 0, 0, 0.3);
    padding: 5px 8px;
    margin-bottom: 5px;
}

.clan-container-allclans-part:last-child {
    margin-bottom: 0px;
}

.clan-container-allclans-part-user {
    font-size: 14px;
    line-height: 10px;
    color: #FFFFFF;
}

.clan-container-allclans-part-rank {
    font-size: 12px;
    color: #B0B0B0;
    line-height: 12px;
}
