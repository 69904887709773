@import '../../scss/global.scss';

.quest-promo-TON-info {
    height: 65px;
    width: 88px;
    min-width: 88px;

    background-color: $main-bg-block;
    border-radius: $main-border-radius-block;

    margin-left: 2.5px;

    cursor: pointer;
}

.quest-promo-TON-info-title {
    color: #fff;
    font-size: 12px;
    line-height: 10px;
    margin-top: 4px;
}

.quest-promo-TON-info-offcanvas-container {
   margin-top: 10%;
    min-height: 340px;
    background-image: url("../../../public/img/airdrop/offcanvas/airdrop.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.quest-promo-TON-info-offcanvas-title {
    font-size: 30px;
    color: #B0B0B0;
}

@media (max-width: 349.09px) {
    .quest-promo-TON-info {
        height: 55px;
        min-width: 70px;
    }
    .quest-promo-TON-info img{
        width: 60px;
        height: 32px; 
    }
}