* {
  font-family: "Advent Pro", sans-serif;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  position: fixed;
  width: 100%;
  background-color: #171027;
}

.main-container {
  height: 100% !important;
  padding: 39px 8px 118px 8px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.main-background {
  background-image: url("../../public/img/background/main-background.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.monster-background {
  background-image: url("../../public/img/background/monster-background.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.coin-background {
  background-image: url("../../public/img/background/bground_main.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.ton-promo-background {
  background-image: url("../../public/img/background/ton-promo-background.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.modal-backdrop.show {
  opacity: 0 !important;
}

.ritual-stone-background {
  background-image: url("../../public/img/ritualstone/background.png");
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;
}

.ritual-stone-open-background {
  background-image: url("../../public/img/ritualstone/background-open.png");
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;
}

.animate-drag {
  animation: drag 0.3s linear infinite;
}

@keyframes drag {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(2px);
  }

  50% {
    transform: translateX(-2px);
  }

  100% {
    transform: translateX(0);
  }
}
