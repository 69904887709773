@import '../../scss/global.scss';

.offline-reward-offcanvas {
    height: auto;
    background-color: rgba(23, 16, 39, 0.90);
    padding-top: 12px;
}

.offline-reward-offcanvas-container-padding {
    padding-top: 15px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 60px;
}

.offline-reward-offcanvas-container {

}

.offline-reward-offcanvas-user {
    font-size: 24px;
    line-height: 24px;
    color: #FFFFFF;
    text-align: center;
    // margin-bottom: 24px;
}

.offline-reward-offcanvas-info-container {
    background-color: rgb(0, 0, 0, 30%);
    width: 226px;
    height: 164px;
    border-radius: 4px;
    padding: 8px;
    padding-bottom: 12px;
    margin-bottom: 90px;
}

.offline-reward-offcanvas-info-title {
    color: #B0B0B0;
    font-size: 16px;
    line-height: 15px;
}

.offline-reward-offcanvas-info-reward {
    font-size: 48px;
    line-height: 48px;
    color: #B28E4C;
}

.offline-reward-offcanvas-info-hours {
    font-size: 11px;
    line-height: 10px;
    color: #B0B0B0;
}

.offline-reward-offcanvas-button-text {
    font-size: 24px;
    color: #FFFFFF;
}

.offline-reward-offcanvas-image-container {
    background-image: url('../../../public/img/background/offline-reward-offcanvas-background.png');
    background-size: 100% auto;
    background-repeat: no-repeat;

    
}