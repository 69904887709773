.container-health-bar {
  width: 240px;
  bottom: 0;
}

.health-bar-img {
  z-index: 2;
}

.health-bar {
  background: #171027;
  height: 27px;
  border-radius: 4px;
  border: 1px solid #B28E4C;
  margin-left: -15px;
  padding: 2px;
}

.health-fill {
  background-color: #DC9F44;
  border-radius: 2px;
  overflow: hidden;
}

.health-text {
  color: white;
  font-size: 16px;
  width: 80%;
  justify-content: center;
  display: flex;
  position: absolute;
}

#energy_logo.anim_energy {
  animation: pulse 0.3s linear infinite;
}

@keyframes pulse {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(2px);
  }

  50% {
    transform: translateX(-2px);
  }

  100% {
    transform: translateX(0);
  }
}