.ritual-stone-modal {
    padding: 8px;
    width: 280px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
  }

.ritual-stone-modal-box-text {
  font-size: 16px;
  line-height: 16px;
  color: #B0B0B0;
  white-space: nowrap;
}

.ritual-stone-modal-box-desc {
  font-size: 10px;
  line-height: 10px;
  color: #B0B0B0;
}

.ritual-stone-modal-box-count {
  color: #B28E4C;
}

.mines-modal-img-container {
  gap: 6px;
}

.ritual-stone-modal-text-container {
  margin-top: 8px;
  color: #B0B0B0;
}

.ritual-stone-modal-add-reward {
  background-color: rgb(76, 54, 60, 70%);
  padding: 0px 10px;
  border-radius: 4px;
  font-size: 10px;
  line-height: 10px;
  bottom: 2px !important;
  right: 2px !important;
}

.ritual-stone-modal-wait-img {
  border: 1px solid rgba(76, 54, 60, 1);
  padding: 2px;
}

.ritual-stone-modal-img-container {
  gap: 6px;
}
