.refferal-friends-item {
    height: 40px;
    background-color:  rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    padding: 5px 8px;

    margin-bottom: 8px;
}

.refferal-friends-item:last-child {
    margin-bottom: 0px;
}

.refferal-friends-user-name {
    font-size: 12px;
    color: #B0B0B0;
    margin-left: 10px;
}

.refferal-friends-reward-img {
    margin-left: 10px;
}

.refferal-friends-reward-amount {
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
}
