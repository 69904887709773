.clan-container-create-modal {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  padding: 10px 16px 16px 16px;
  margin-left: 35px;
  margin-right: 35px;
}

.clan-container-create-modal-label {
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
}

.clan-container-create-modal-desc {
  font-size: 12px;
  line-height: 12px;
  color: #b0b0b0;
}

.clan-container-create-modal-input {
  background: rgba(176, 176, 176, 0.2);
  border: none;
  border-radius: 4px;
  font-size: 16px;
  color: #b0b0b0;
  padding-left: 16px;
  margin-top: 8px;
}

.clan-container-create-modal-textarea {
  background: rgba(176, 176, 176, 0.2);
  border: none;
  border-radius: 4px;
  font-size: 16px;
  color: #b0b0b0;
  padding-left: 16px;
  margin-top: 8px;
  outline: none !important;
}

.clan-container-create-modal-button {
  font-size: 16px;
  color: #b0b0b0;
  margin-top: 16px;
}

.clan-container-create-modal-button-text {
  font-size: 12px;
  margin-right: 4px;
}

.clan-container-create-modal-price-animate {
  animation: pulse 0.3s linear infinite;
}

@keyframes pulse {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(2px);
  }

  50% {
    transform: translateX(-2px);
  }

  100% {
    transform: translateX(0);
  }
}

.clan-container-create-modal-button-text-red {
    color: rgb(177, 61, 88);
}