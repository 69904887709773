.ton-promo-sections-navbar {
    padding-top: 10px;
    padding-bottom: 18px;
}

.ton-promo-sections-navbar-item {
    // padding-left: 24px;
    // padding-right: 24px;

    font-size: 14px;
    color: #B0B0B0;
    width: 93px;

}

.ton-promo-sections-navbar-item-active {
    background-color: rgb(0, 0, 0, 50%);
    border-radius: 4px;
}