@import '../../scss/global.scss';

.academy-container {
    background-color: $main-bg-block;
    
    border-radius: $main-border-radius-block;

    padding: 8px;
    padding-top: 10px;
    padding-bottom: 17px;
}

.academy-container-title {
    color: #FFFFFF;
    font-size: 20px;
}

.academy-container-cap {
    margin-top: 12px;
}

.academy-container-items {
    margin-top: 12px;
}

.container-science-bar {
    width: 64px;
}
  
.science-bar {
    background: #171027;
    height: 14px;
    border-radius: 4px;
    border: 1px solid #B28E4C;
    padding: 1px;
}

.science-bar-notactive {
    background: #171027;
    height: 14px;
    border-radius: 4px;
    border: 1px solid #B0B0B0;
    padding: 2px;
}
  
.science-fill {
    background-color: #3D6250;
    border-radius: 2px;
    overflow: hidden;
}
  
.science-text {
    color: #B0B0B0;
    font-size: 9px;
    width: 90%;
    justify-content: center;
    display: flex;
    position: absolute;
}