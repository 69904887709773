@import '../../scss/global.scss';

.ritual-stone-container {
  //background-color: $main-bg-block;
  //border-radius: $main-border-radius-block;
  padding-left: 8px;
  padding-right: 8px;
}

.ritual-stone-container-button_text {
  font-size: 14px;
  line-height: 14px;
  text-decoration: none;
  color: #B0B0B0;
}

.ritual-stone-container-desc {
  background-color: rgb(0, 0, 0, 0.7);
  border-radius: 4px;
  padding: 8px;
}

.ritual-stone-container-desc-title {
  font-size: 18px;
  line-height: 18px;
  color: #B28E4C;
}

.ritual-stone-container-desc-text {
  font-size: 12px;
  line-height: 12px;
  color: #B0B0B0;
  margin-top: 8px;
  margin-left: 8px;
}


.main_splot {
  //position: relative;
  //background: #000;
  //height: 450px;
  //width: 450px;
  //display: flex;
  //align-items: center;
  //justify-content: center;
  //background: #000;

  filter: blur(8px) contrast(18);
  mix-blend-mode: screen;

  > span {
    position: absolute;

    &:nth-of-type(1) {
      left: 50%;
      width: 150px;
      height: 150px;
      animation: spin-3 8s ease-in-out infinite forwards;

      span {
        width: 30px;
      }
    }

    &:nth-of-type(2) {
      left: 20%;
      width: 150px;
      height: 100px;
      animation: spin-4 6s ease-in-out infinite;

      span {
        width: 21px;
      }
    }

    &:nth-of-type(3) {
      left: 48%;
      top: 48%;
      width: 100px;
      height: 100px;
      animation: spin-5 3.5s ease-in-out infinite forwards;

      span {
        width: 25px;
      }
    }

    &:nth-of-type(4) {
      left: 28%;
      top: 48%;
      width: 200px;
      height: 200px;
      animation: spin-2 4.5s ease-in-out infinite forwards;

      span {
        width: 20px;
      }
    }

    &:nth-of-type(5) {
      left: 50%;
      top: 25%;
      width: 200px;
      height: 200px;
      animation: spin-6 3.5s ease-in-out infinite forwards;

      span {
        width: 20px;
      }
    }

    > span {
      position: absolute;
      background: #BCF0F7;
      right: 20px;
      top: 20px;
      aspect-ratio: 1;
      border-radius: 50%;
    }
  }
}

.splot {
  position: relative;
  width: 80px;
  aspect-ratio: 1;
  background: #BCF0F7;
  border-radius: 50%;

  > div {
    position: absolute;
    aspect-ratio: 1;
    border-radius: 50%;
    background: transparent;

    > span {
      position: absolute;
      aspect-ratio: 1;
      background: #BCF0F7;
      border-radius: 50%;
    }
  }

  > div:nth-child(1) {
    left: -5px;
    width: 70px;
    animation: spin-1 5s ease-in infinite forwards;
    > span {
      width: 50px;
    }
  }

  > div:nth-child(2) {
    left: 10px;
    bottom: 0;
    width: 80px;
    animation: spin-2 3s ease-in 1.5s infinite forwards;
    > span {
      width: 60px;
    }
  }
}

@keyframes spin-1 {
  0% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(450deg);
  }
}

@keyframes spin-2 {
  0% {
    transform: rotate(-50deg);
  }
  100% {
    transform: rotate(310deg);
  }
}

@keyframes spin-3 {
  0% {
    transform: rotate(220deg);
  }
  100% {
    transform: rotate(580deg);
  }
}

@keyframes spin-4 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin-5 {
  0% {
    transform: rotate(-100deg);
  }
  100% {
    transform: rotate(260deg);
  }
}

@keyframes spin-6 {
  0% {
    transform: rotate(210deg);
  }
  100% {
    transform: rotate(570deg);
  }
}