.mines-container-info {
    min-height: 147px;
    max-height: 147px;
    padding: 12px 15px;
    background-color: rgb(0, 0, 0, 30%);
    border-radius: 4px;
}

.mines-container-info-title {
    color: #B28E4C;
    font-size: 32px;
    line-height: 30px;    
}

.mines-container-info-descriptions {
    width: 228px;
    max-width: 228px;
    border-radius: 4px;
    background-color: rgb(0, 0, 0, 30%);
    height: 84px;
    padding-left: 25px;
    padding-right: 4px;
}

.mines-container-info-description {
    color: #B0B0B0;
    font-size: 12px;
    line-height: 12px;
}