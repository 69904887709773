.clan-view-user-profile-part{
    border-radius: 4px;
    height: 40px;
    background-color: rgb(0, 0, 0, 0.3);
    padding: 5px 8px;
    margin-bottom: 5px;
    margin-top: 4px;
}

.clan-view-user-profile-part-user {
    font-size: 14px;
    line-height: 10px;
    color: #FFFFFF;
}

.clan-view-user-profile-part-rank {
    font-size: 12px;
    color: #B0B0B0;
    line-height: 10px;
}

.clan-view-user-profile-part:first-child {
    margin-top: 0px;
}
.clan-view-user-profile-part:last-child {
    margin-bottom: 0px;
}