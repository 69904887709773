.academy-info-modal-container {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
    padding: 10px 16px 16px 16px;
    margin-left: 35px;
    margin-right: 35px;
  }

.academy-info-modal-title {
    font-size: 20px;
    color: #B28E4C;
}

.academy-info-modal-desc {
    margin-top: 4px;
    font-size: 14px;
    color: #B0B0B0;
}