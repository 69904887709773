@import "../../scss/global.scss";

.is-first-time-slider {
  backdrop-filter: blur(5px);
}

.is-first-time-slider-container {
  background-color: rgb(23, 16, 39, .9);
}

.is-first-time-slider-container {
  height: 426px;
}

.is-first-time-slider-btn {
  border: none;
  background: none;
  outline: none;
}

.is-first-time-slider-item {
  height: 310px !important;
}

.is-first-time-slider-item div {
    padding-left: 8px;
    padding-right: 8px;
    height: 310px !important;
}

.is-first-time-slider-dots button {
    width: 12px;
    height: 12px;
    border: none;
    border-radius: 50%;
    background-color: #D9D9D9;
}

.is-first-time-slider-dots button.carousel__dot--selected {
    background-color: #B28E4C;
}

.is-first-time-slider-btn-text {
  color: #B0B0B0;
  font-size: 24px;  
}

.is-first-time-slider-item-text {
  color: white;
  font-size: 15px;
  line-height: 15px !important;
}
.width-368 {
  width: 368px !important;
}

.carousel__inner-slide {
  display: flex;
  justify-content: center;
}

.is-first-time-slider-item-bonus {
  background-color: rgb(76, 54, 60, .7);
  width: 55px;
  height: 19px !important;
  color: #ffffff;
  font-size: 20px;
  line-height: 20px;
  border-radius: 4px;
  bottom: 15px !important;
}