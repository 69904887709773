@import '../../../scss/global.scss';

.header-item-container {
    width: 88px;
    height: 24px;
    background-color: rgb(0, 0, 0, 20%);
    border-radius: $main-border-radius-block;
}

.header-item-count {
    color: #B0B0B0;
    font-size: 12px;
    line-height: 10px;
}

.header-item-icon {
    left: 10px;
}

.header-item-lock {
    left: 13px; 
}

.header-item-link {
    background-color: rgb(178, 142, 76, 50%);
    height: 20px;
    min-width: 15px;
    color: #B0B0B0;
    font-size: 20px;
    border-radius: $main-border-radius-block;
}

.header-item-link:hover {
    color: #B0B0B0;
}