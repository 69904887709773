.line_box_activate {
  background-color: rgb(0, 0, 0, 50%);
  width: 100%;
  height: auto;
  // border: 1px solid #b28e4c;
  position: relative;
  padding: 3px 17px 20px 20px;

  height: 126px;
}

.hero-lines-view-activate-name {
    font-size: 20px;
    color: white;
    margin: 0;
}

.hero-lines-view-activate-name {
  font-size: 20px;
  line-height: 24px;
  color: white;
  margin: 0;
}

.hero-lines-view-activate-desc {
  font-size: 12px;
  color: #B0B0B0;
}

@media (max-width: 349.09px) {
  .line_box_activate {
    height: auto;
  }  
}