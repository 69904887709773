.hero-details-modal {
  height: 156px;
  background-color: rgb(23, 16, 39, 90%);
  border-radius: 4px;
  overflow: hidden;
}

.hero-details-modal-image {
  width: 225px;
  height: 225px;
  filter: blur(5px);
}

.hero-details-modal-buy-image {
  filter: none;
}

.hero-details-modal-title {
  font-size: 18px;
  color: #b0b0b0;
}

.hero-details-modal-name {
  font-size: 22px;
  color: #b28e4c;
  line-height: 22px;
}

.hero-details-modal-btn {
  font-size: 16px;
  color: #b0b0b0;
}

.hero-details-modal-info {
  color: #fff;
  font-size: 18px;
  line-height: 18px;
  text-align: end;
}

.hero-details-modal-info span {
  color: #b28e4c;
}

.hero-details-modal-price {
  color: #fff;
  font-size: 16px;
}

.hero-details-modal-price-red {
  color: rgb(177, 61, 88);
}

.hero-details-modal-price-animate {
  animation: pulse 0.3s linear infinite;
}

@keyframes pulse {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(2px);
  }

  50% {
    transform: translateX(-2px);
  }

  100% {
    transform: translateX(0);
  }
}

.hero-details-modal-buy-title {
  color: #B0B0B0;
  font-size: 16px; 
  line-height: 14px; 
  
  @media (max-width: 384px) {
    font-size: 14px;
  }
}

.hero-details-modal-buy-name {
  color: #B28E4C;
  font-size: 24px;  
  text-align: center;
}

.hero-details-modal-buy-continue {
  color: #B0B0B0;
  font-size: 16px;  
}