.battles-reward-modal {
    height: 170px;
    background-color: rgb(0, 0, 0, 0.7);
    border-radius: 4px;
    overflow: hidden;
}

.battles-reward-modal-title {
    font-size: 16px;
    color: #B0B0B0;
}

.battles-reward-modal-count {
    font-size: 32px;
    color: #B4AD9B;
}

.battles-reward-modal-button-text {
    font-size: 18px;
    color: #B0B0B0;
}