.ton-promo-sections-exchange-conditions {
    padding-top: 19px;
}

.ton-promo-sections-exchange-conditions-title {
    font-size: 14px;
    // max-width: 280px;
    color: #ffffff;
    margin-left: 10px;

    margin-bottom: 21px;
}

.ton-promo-sections-exchange-conditions-item {
    height: 50px;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: rgb(0, 0, 0, 60%);
    margin-bottom: 8px;
}

.ton-promo-sections-exchange-conditions-item-title {
    color: #ffffff;
    font-size: 14px;
}

.ton-promo-sections-exchange-conditions-item-complete {
    opacity: 60%;
}