.academy-container-item {
    margin-top: 14px;
}
.academy-container-items-column {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    width: 112px;
    margin-top: 8px;
    padding-bottom: 14px;
}

.academy-container-items-image {
    padding: 2px;
    margin-bottom: 6px;
}

.academy-container-item-to {
    margin-top: 20px;
}