.ton-promo-sections-exchange-invoice-history-item {
    height: 20px;
    background-color: rgb(0, 0, 0, 60%);
    margin-bottom: 2px;
    border-radius: 4px;
}

.ton-promo-sections-exchange-invoice-history-header-item-date {
    color: #B0B0B0;
    font-size: 10px;
}

.ton-promo-sections-exchange-invoice-history-header-item-ton {
    color: #ffffff;
    font-size: 12px;
}

.ton-promo-sections-exchange-invoice-history-header-item-wallet {
    color: #B0B0B0;
    font-size: 10px;
}

.ton-promo-sections-exchange-invoice-history-header-item-status-registered {
    font-size: 10px;
    color: #B28E4C;
}

.ton-promo-sections-exchange-invoice-history-header-item-status-in-processing {
    font-size: 10px;
    color: #237DD1;
}

.ton-promo-sections-exchange-invoice-history-header-item-status-completed {
    font-size: 10px;
    color: #218D45;
}