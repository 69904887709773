@import '../../scss/global.scss';

.event-container {
    background-color: $main-bg-block;
    
    border-radius: $main-border-radius-block;

    padding: 8px;
    padding-top: 10px;
    padding-bottom: 17px;
}

.event-container-navbar {
    margin-top: 8px;
}

.event-container-navbar-item {
   padding-left: 24px;
   padding-right: 24px;

   font-size: 14px;
   color: #B0B0B0;
}

.event-container-navbar-item-active {
    background-color: rgb(0, 0, 0, 50%);
    border-radius: 4px;
}

.event-container-items-container {
    margin-top: 8px;
}