.hero-new-modal {
    height: 156px;
    background-color: rgb(23, 16, 39, 90%);
    border-radius: 4px;
    padding: 8px;
    padding-right: 13px;
}

.hero-new-modal-title {
    font-size: 16px;
    color: #B0B0B0;
}

.hero-new-modal-name {
    font-size: 32px;
    color: #B28E4C;
    text-align: center;
    line-height: 38px;
}

.hero-new-modal-btn {
    font-size: 16px;
    color: #B0B0B0;
}