.hero-ritualstone {
    display: flex;
    justify-content: center;
    width: auto;
    height: 120px;
    margin-top: 26px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
}
  
.hero-ritualstone-container-title {
    font-size: 18px;
    line-height: 16px;
    color: #B28E4C;
}

.hero-ritualstone-container-description {
    margin-top: 8px;
    margin-left: 8px;
    font-size: 14px;
    line-height: 10px;
    color: #B0B0B0;
}

.hero-ritualstone-container-conditions {
    margin-top: 4px;
    margin-left: 8px;
    font-size: 10px;
    color: #B0B0B0;
}

.hero-ritualstone-container-button {
    font-size: 12px;
    margin-top: 8px;
}

.hero-ritualstone-container-button_text {
    color: #B0B0B0;
}

.hero-ritualstone-img {
    margin-right: 0px;
}