.event {
    background-color: rgb(176, 176, 176, 30%);
    width: 40px;
    height: 40px;
    border-radius: 4px;
}
.event-link {
    text-decoration: none !important;
}

.event-item {
    margin: 6px;
} 

.animated-svg-1 {
    animation: glow 1.5s infinite alternate;
}

@keyframes glow {
    0% {
        stroke: #000000;
        filter: drop-shadow(0 0 8px #000000);
    }
    100% {
        stroke: #B28E4C;
        filter: drop-shadow(0 0 10px #B28E4C);
    }
}