.qr-code-container {
    height: 100vh;
    background-color: #171027;
}

.qr-code-title,
.qr-code-bot {
    color: #B0B0B0;
}

.qr-code-title {
    margin-bottom: 25px;
}

.qr-code-bot {
    margin-top: 25px;
    font-size: 20px;
}