@import '../../scss/global.scss';

.quests-container {
    height: 100%;

    background-color: $main-bg-block; 
    border-radius: $main-border-radius-block;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding: 8px;
}