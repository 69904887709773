.ton-promo-sections-exchange-invoice-withdrawal {
  padding-top: 20px;
}

.ton-promo-sections-exchange-invoice-form {
  height: 40px;
  background-color: rgb(0, 0, 0, 60%);
  border-radius: 4px;

  padding-left: 8px;
  padding-right: 8px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.ton-promo-sections-exchange-invoice-form img {
  margin-right: 20px;
}

.ton-promo-sections-exchange-invoice-form-input {
  background: none;
  border: none;
  color: #b0b0b0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input:focus {
  outline: none !important;
  border-color: none;
  box-shadow: none;
}

.ton-promo-sections-exchange-invoice-form-btn {
    border: none;
    background: rgb(178, 142, 76, 60%);
    min-width: 100px;
    width: 100px;
    color: #B0B0B0;
    height: 26px;
    border-radius: 4px;
}

.ton-promo-sections-exchange-invoice-withdrawal-btn {
    margin-top: 8px;
    border: none;
    background: rgb(178, 142, 76);
    color: #ffffff;
    height: 40px;
    border-radius: 4px;  
    font-size: 20px;  
}

.ton-promo-sections-exchange-invoice-withdrawal-min {
  color: #B0B0B0;
  font-size: 12px;  
  margin-top: 4px;
}