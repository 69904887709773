.rating-container-item-gold {
    border-radius: 4px;
    height: 40px;
    background-color: rgb(0, 0, 0, 60%);
    padding: 5px 8px;

    margin-bottom: 5px;
}

// .rating-container-item-gold:last-child {
//     margin-bottom: 0px;
// }

.rating-container-item-user {
    font-size: 12px;
    line-height: 10px;
    color: #B0B0B0;
}

.rating-container-item-count {
    font-size: 12px;
    color: #FFFFFF;
    line-height: 10px;
}

.rating-container-item-gold-opacity {
    background-color: rgb(0, 0, 0, 40%);
}

.rating-container-item-gold-user {
    background-color: rgb(178, 142, 76, 40%);
}

.rating-container-item-place {
    width: 28px;
    margin-right: 12px;
}