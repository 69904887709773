.mini-navbar-button {
  background-color: rgba(23, 16, 39, 0.5);
  border-radius: 4px;
  padding: 10px;
  filter: blur(0) !important;
}


.mini-navbar-button-notification {
  width: 10px;
  height: 10px;
  background-color: #B13D58;
}