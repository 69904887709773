@import '../../scss/global.scss';

.refferal-info {
    background-color: $main-bg-block;
    border-radius: $main-border-radius-block;
    padding: 12px 20px;
    margin-top: 5px;
}

.refferal-info-text {
    font-size: 18px;
    color: #B0B0B0;
    line-height: 20px;
}