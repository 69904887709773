.shop-chest-container {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 4px 4px 10px 4px;
  height: 245px;
  width: 32%;
}

.shop-chest-name {
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  border-radius: 4px;
  padding: 4px 8px;
  height: 37px;

  @media (max-width: 360px) {
    font-size: 12px;
  }
}

.shop-chest-img {
  height: 117px;
  margin-top: -2px;
}

.chest-old-price {
  font-size: 12px;
  color: rgba(176, 176, 176, 1);
}

.chest-sale {
  margin-left: 3px;
  font-size: 12px;
  color: rgba(33, 141, 69, 1);
}

.chest-price {
  margin-bottom: 1px;
  font-size: 18px;
}

.chest-price-container {
  margin-bottom: 9px;
}

.chest-buy-btn {
  margin-top: 10px;
}

.shop-buy-text {
  color: rgba(176, 176, 176, 1);
  font-size: 16px;
}

.chest-price-red {
  color: rgb(177, 61, 88) !important;
}

.chest-price-animate {
  animation: pulse 0.3s linear infinite;
}

@keyframes pulse {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(2px);
  }

  50% {
    transform: translateX(-2px);
  }

  100% {
    transform: translateX(0);
  }
}