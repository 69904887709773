.clan-container-myclan-hat {
    display: flex;
    justify-content: center;
    width: auto;
    height: 160px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
}

.clan-container-myclan-footer {
    display: flex;
    justify-content: center;
    width: auto;
    height: 60px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    margin-top: 4px;
}

.clan-container-myclan-info {
    margin-top: 18px;
    margin-right: 8px;
}

.clan-container-myclan-logo {
    margin-top: 18px;
    margin-left: 8px;
}

.clan-container-myclan-title {
    font-size: 24px;
    line-height: 20px;
    color: #B28E4C;
}

.clan-container-myclan-description {
    font-size: 14px;
    line-height: 12px;
    color: #B0B0B0;
    margin-top: 8px;
}

.clan-container-myclan-stats {
    font-size: 16px;
    line-height: 16px;
    color: #B0B0B0;
    margin-top: 24px;
    margin-left: 8px;
}

.clan-container-myclan-code {
    margin-top: 4px;
    font-size: 12px;
    color: #B0B0B0;
    line-height: 12px;
}

.clan-container-myclan-stats-count {
    color: #FFFFFF;
    margin-left: 8px;
}

.clan-container-myclan-button {
    font-size: 14px;
    margin-top: 4px;
}

.clan-container-myclan-button-science {
    font-size: 14px;
    margin-left: 8px;
}

.clan-container-myclan-button_text {
    color: #B0B0B0;
}

.clan-container-myclan-bonus {
    margin-top: 4px;
}

.clan-container-myclan-bonus-title {
    margin-top: 8px;
    font-size: 14px;
    line-height: 16px;
    color: #B28E4C;
}

.clan-container-myclan-item {
    margin-left: 12px;
    margin-right: 12px;
} 

.clan-container-myclan-item-icon {
    margin-bottom: 4px;
}

.clan-container-myclan-item-title {
    margin-top: 4px;
    line-height: 14px;
    font-size: 12px;
    color: #B0B0B0;
    text-align: center;
    max-width: 48px;
}

.clan-container-myclan-part{
    border-radius: 4px;
    height: 40px;
    background-color: rgb(0, 0, 0, 0.3);
    padding: 5px 8px;
    margin-bottom: 5px;
    margin-top: 4px;
}

.clan-container-myclan-part-user {
    font-size: 14px;
    line-height: 10px;
    color: #FFFFFF;
}

.clan-container-myclan-part-rank {
    font-size: 12px;
    color: #B0B0B0;
    line-height: 10px;
}