.ton-promo-sections-nfts-user-collection {
    height: 30px;
    border-radius: 4px;
    background-color: rgb(0, 0, 0, 50%);
    margin-left: 4px;
    width: 104px;
    min-width: 104px;

    color: #B0B0B0;
    font-size: 10px;
}