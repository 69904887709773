@import '../../scss/global.scss';

.refferal-reward-container {
    height: 219px;
    min-height: 219px;
    background-color: $main-bg-block;
    border-radius: 0px;
    padding: 8px;
    padding-bottom: 25px;
}

.refferal-reward-btn-container {
    padding: 8px;
    padding-top: 0px !important;
}