.shop-crystals-item {
    height: 180px; 
    min-width: 32%;
    width: 32%;   
    max-width: 32%; 
    border-radius: 4px;
    background-color: rgb(0, 0, 0, 50%);
    
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 7px;
}

.shop-crystals-item-name {
    color: #B28E4C;
    font-size: 12px; 
    padding-top: 7px;   
    line-height: 12px;
    height: 31px;
}

.shop-crystals-item-price {
    color: #fff;
    font-size: 16px;    
    line-height: 19px;

    margin-bottom: 11px;
}

.shop-crystals-item-count {
    font-size: 10px;
    line-height: 10px;
    color: #B0B0B0;
    max-width: 66px;
}