.chest-info-modal-container {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
    padding: 10px 16px 16px 16px;
    margin-left: 35px;
    margin-right: 35px;
  }
  
  .chest-info-modal-label {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .chest-info-modal-fragments-container {
    margin-bottom: 13px;
  }
  
  .chest-info-modal-image-container {
    gap: 8px;
  }
  
  .chest-info-modal-text {
    font-size: 12px;
    color: rgba(176, 176, 176, 1);
  }
  
  .chest-info-modal-image {
    border: 1px solid rgba(76, 54, 60, 1);
    padding: 2px;
  }
  
  .chest-info-modal-hero-name {
    border-radius: 6px;
    background-color: rgba(76, 54, 60, 0.7);
    font-size: 10px;
    bottom: 2px;
    right: 2px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 10px;

    text-align: center;
  }
  
  .chest-info-modal-back-btn {
    font-size: 20px;
    margin-top: 20px;
  }