.ton-promo-sections-nfts-search {
    height: 30px;
    border-radius: 4px;
    background-color: rgb(0, 0, 0, 50%);

    padding: 6px 8px;
    margin-right: 4px;
}

.ton-promo-sections-nfts-search-title {
    font-size: 10px;
    color: #B0B0B0; 
    opacity: 50%;   
}