.hero-mines-container {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 0 2px;
  padding-top: 3px;
}

.hero-mines-gray-text {
  color: rgba(176, 176, 176, 1);
  font-size: 12px;
  line-height: 12px;

  span {
    color: rgba(178, 142, 76, 1);
  }
}

.hero-mines-label {
  font-size: 11px;
  line-height: 12px;
  padding-left: 4px;
  margin-bottom: 2px;
}

.hero-mines-reward-container {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 4px 4px;
}

.hero-mines-btn-container {
  width: 82px;
  height: 26px;
  padding-left: 4px;
}

.hero-mines-imgs {
  padding-right: 4px;
  padding-left: 15px;
}