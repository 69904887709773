.coin-energy-bar-info {
    background-color: #171027;

    width: 136px;

    padding-left: 8px;
    padding-right: 6px;

    padding-top: 4px;
    padding-bottom: 4px;

    border-radius: 4px;
}

.coin-energy-bar-info-count {
    font-size: 8px;
    line-height: 6px;
    color: #F5F5F5;
    font-weight: 600;
}

.coin-energy-bar-info-text {
    font-size: 8px;
    line-height: 6px;
    color: #FFFFFF;
}

.coin-energy-bar-info div {
    margin-bottom: 4px;
}