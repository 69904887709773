.hero-lines-view-combined {
  // background-color: rgb(0, 0, 0, 50%);
  width: 100%;
  height: auto;
  position: relative;
  padding: 3px 7px 12px 20px;

  height: 126px;
}

.hero-lines-view-combined-name {
  font-size: 20px;
  line-height: 24px;
  color: white;
  margin: 0;
}

.hero-lines-view-combined-desc {
  font-size: 12px;
  color: #b0b0b0;
}

@media (max-width: 349.09px) {
  .line_box_activate {
    height: auto;
  }
}

.hero-lines-view-combined-stat {
  height: 25px;
  width: 175px;
  padding: 1px;
  border: 2px solid rgba(178, 142, 76, 1);
}

.hero-lines-view-combined-progress {
  background-color: rgba(54, 63, 93, 1);
}
