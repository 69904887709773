.ton-promo-balance-title {
    font-size: 10px;
    line-height: 8px;
    color: #fff;
    margin-bottom: 2px;
}

.ton-promo-balance-count {
    font-size: 20px;
    color: #fff;  
    line-height: 22px;  
}