.hero-mine-wait-img {
  border: 1px solid rgba(76, 54, 60, 1);
  padding: 2px;
}

.hero-mines-wait-container {
  opacity: 30%;
}

.hero-mines-imgs-wait {
  padding-right: 4px;
  padding-left: 15px;
}
