@import '../../scss/global.scss';

.shop-container {
    background-color: $main-bg-block;
    
    border-radius: $main-border-radius-block;

    padding: 8px;
    padding-top: 10px;
    padding-bottom: 17px;
}

.shop-title {
    font-size: 16px;
    color: #FFFFFF;
    line-height: 19px;

    margin-left: 9px;
}

.shop-navigate-btns {
    font-size: 14px;
    color: rgba(176, 176, 176, 1);
    margin-top: 18px;
    margin-bottom: 18px;
}

.shop-navigate-btn {
    width: 93px;
    height: 20px;
}

.shop-navigate-btn-active {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: $main-border-radius-block;
}