.mini-navbar {
    // min-width: 120px;
    // min-width: 167px;
    // min-width: 190px;
    // min-width: 240px;
    min-width: 290px;
    height: 56px;

    background-color: rgba(23, 16, 39, 0.7);
    border-radius: 4px;

    top: 40px;
    right: 0px;

    padding-top: 5px;
    padding-bottom: 4px;

    padding-right: 5px;
    padding-left: 15px;
}

.mini-navbar-item {
    // margin-left: 6px;
    // margin-right: 6px;
    width: 50px;
} 

.mini-navbar-item-icon {
    margin-bottom: 4px;
}

.mini-navbar-item-title {
    line-height: 8px;
    font-size: 10px;
    color: #B0B0B0;

    text-align: center;
    max-width: 48px;
}

.mini-navbar-item-title-mini {
    line-height: 14px;
    font-size: 10px;
    color: #B0B0B0;

    text-align: center;
    max-width: 48px;
}

.mini-navbar-item-nav-link {
    text-decoration: none !important;
}