.mail-container-view-modal {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
    padding: 8px 16px;
    margin-left: 10px;
    margin-right: 10px;
     
}
.mail-container-view-message {
    margin-top: 4px;
    height: 40px;
}

.mail-container-view-message-envelope {
    margin-right: 8px;
}

.mail-container-view-message-title{
    padding: 1px 8px;
}

.mail-container-view-message-subject {
    font-size: 18px;
    line-height: 18px;
    color: #ffffff;
}

.mail-container-view-body {
    margin-top: 8px;
    height: 160px;
    border-radius: 4px;
    background-color: rgb(176, 176, 176, 0.1);
    padding: 8px 8px;
}

.mail-container-view-body-text {
    color: #B0B0B0;
    font-size: 16px;
    line-height: 16px;
}

.mail-container-view-attach {
    margin-top: 8px;
    height: 80px;
    border-radius: 4px;
    background-color: rgb(176, 176, 176, 0.1);
}

.mail-container-view-attach-item {
    border: 1px solid rgba(76, 54, 60, 1);
    margin-left: 8px;
    padding: 2px;
    width: auto;
    width: 64px;
    min-width: 64px;
    max-width: 64px;
    height: 64px;
}

.mail-container-view-attach-count {
    border-radius: 6px;
    background-color: rgba(76, 54, 60, 0.7);
    font-size: 10px;
    bottom: 2px;
    right: 2px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 10px;

    text-align: center;
}

.mail-container-view-button {
    margin-top: 8px;
    color: #B0B0B0;
    font-size: 16px;
    line-height: 16px; 
}