.coin-user-gold-count-container {
    margin-top: 30px;
}

.coin-user-gold-count {
    line-height: 28px;
    font-size: 32px;
    font-weight: 700;
    color: #DC9F44;
    margin-left: 12px;
    text-shadow: rgba(0, 0, 0, 0.5) 4px 4px 4px;
    animation: money 0.5s linear ;
}