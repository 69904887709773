.hero-mines-add-button {
  border: 1px solid rgba(176, 176, 176, 0.1);
  width: 56px;
  height: 56px;
  min-width: 56px;
  min-height: 56px;
}

.hero-mines-add-container {
  margin: 5px 0;
}