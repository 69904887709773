.academy-science-modal-container {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
    padding: 12px 12px 12px 12px;
    margin-left: 35px;
    margin-right: 35px;
  }

.academy-science-modal-desc-name {
    font-size: 24px;
    color: #B28E4C;
    line-height: 18px;
    padding-left: 12px;
}

.academy-science-modal-desc-level {
    font-size: 16px;
    color: #FFFFFF;
    padding-left: 12px;
}

.academy-science-modal-desc-details {
    font-size: 12px;
    color: #B0B0B0;
    line-height: 12px;
    padding-left: 12px;
}

.academy-science-modal-progress-bar {
    padding-left: 12px;
    padding-top: 4px;
}

.container-progress-bar {
    width: 100%;
}
  
.progress-bar {
    background: #171027;
    height: 22px;
    border-radius: 4px;
    border: 1px solid #B28E4C;
    padding: 2px;
}
  
.progress-bar-fill {
    background-color: #3D6250;
    border-radius: 2px;
    overflow: hidden;
}

.progress-text {
    color: white;
    font-size: 12px;
    width: 80%;
    justify-content: center;
    display: flex;
    position: absolute;
}

.academy-science-modal-buttons {
    margin-top: 12px;
}

.academy-science-modal-button-text {
    color: #FFFFFF;
    font-size: 12px;
}

.academy-science-modal-buttons-count {
    margin-top: 8px;
    font-size: 10px;
    color: #B0B0B0;
}

.academy-science-modal-info {
    font-size: 14px;
    color: #FFFFFF;
}