.chest-open-offcanvas-cont {
    background-color: rgba(23, 16, 39, 0.9);
    padding: 13px 7px 7px 7px;
    overflow-y: scroll;
  }
  
  .chest-open-offcanvas-label {
    font-size: 16px;
    margin-bottom: 13px;

    padding-left: 8px;
  }
  
  .chest-open-offcanvas-roulette {
    height: 120px;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 10px 8px;
    border-radius: 4px;
    margin-bottom: 20px;
    width: 370px;
  }
  
  .chest-open-offcanvas-roulette-ramka {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
  
  .chest-open-offcanvas-hero-spin {
    border: 1px solid rgba(76, 54, 60, 1);
    padding: 5px;
  }
  
  .chest-open-offcanvas-btns {
    height: 38px;
  }
  
  .chest-open-offcanvas-btn-text-big {
    color: rgba(176, 176, 176, 1);
    font-size: 24px;
  }
  
  .chest-open-offcanvas-btn-text {
    color: rgba(176, 176, 176, 1);
    font-size: 12px;
  }
  
  .chest-open-offcanvas-heroes {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    color: rgba(176, 176, 176, 1);
    font-size: 12px;
    padding: 0 4px 4px 4px;
    margin-top: 20px;
  }
  
  .chest-open-offcanvas-hero {
    border: 1px solid rgba(76, 54, 60, 1);
    padding: 2px;
  }
  
  .roulette-animate {
    transition: transform 7s ease;
  }
  
  .roulette-animate-fast {
    transition: transform 0.5s ease;
  }
  
  .roulette-gap {
    gap: 15px;
    margin-left: 10px;
  }
  
  animate ease {
  
  }
  
  @keyframes roulette {
    0% {
      transform: translateX(0);
    }
  
    40% {
      transform: translateX(-4000px);
    }
  
    70% {
      transform: translateX(-5400px);
    }
  
    100% {
      transform: translateX(-5500px);
    }
  }