.combo-stars-modal {
    width: 290px;
    height: 358px;
    background-color: rgb(0, 0, 0, 70%);
    border-radius: 4px;
    padding-bottom: 8px;
}

.combo-stars-modal-title-container {
    padding: 8px;
}

.combo-stars-modal-title {
    color: #FFFFFF;
    font-size: 20px;
    height: 37px;
    background-color: rgb(0, 0, 0, 50%);
    border-radius: 4px;
    padding-left: 9px;
    padding-right: 9px;
}

.combo-stars-modal-item {
    width: 52px;
    height: 52px;
    border: 1px solid #4C363C;
}

.combo-stars-modal-item-text {
    height: 9px;
    width: 27px;
    background-color: #4C363C;
    border-radius: 4px;
    color: #FFFFFF;
    line-height: 5px;
    font-size: 10px;
    right: 1px;
    top: 40px;
}

.combo-stars-modal-buy {
    font-size: 12px;
    color: #B0B0B0;    
    line-height: 8px;
}

.combo-stars-modal-price {
    color: #FFFFFF;
    font-size: 16px;
    line-height: 17px;    
}

.combo-stars-modal-timer {
    color: #ffffff !important;
    font-size: 20px;
}

.combo-stars-modal-timer span:last-child{
    width: 75px;
}