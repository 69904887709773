.ton-promo-sections-exchange-invoice-history {
    margin-top: 30px;
    padding-bottom: 20px;
}

.ton-promo-sections-exchange-invoice-history-header {
    margin-bottom: 6px;
}

.ton-promo-sections-exchange-invoice-history-header-item {
    color: #B0B0B0;
    font-size: 10px;   
}