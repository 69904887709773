.open-chest-modal-cont {
  width: 300px;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  border-radius: 4px;
  padding: 16px;
}

.open-chest-modal-text {
  font-size: 14px;
  line-height: 12px;
  margin-bottom: 25px;
}

.open-chest-modal-btn {
  margin-top: 40px;
}

.open-chest-modal-btn-text {
  font-size: 24px;
}