.clan-container-allclans-search {
    height: 40px;
    width: auto;
    border-radius: 4px;
    background-color: rgb(0, 0, 0, 50%);
    padding: 6px 8px;
}

.clan-container-allclans-search-title {
    font-size: 10px;
    color: #B0B0B0; 
    opacity: 50%;   
}

.clan-container-allclans-search-form-input {
    background: none;
    border: none;
    color: #b0b0b0;
    padding-left: 8px;
}

.clan-container-allclans-search-form-button {
    border: none;
    background: rgb(178, 142, 76, 60%);
    min-width: 80px;
    width: 100px;
    color: #B0B0B0;
    border-radius: 4px;
    font-size: 12px;
    line-height: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
