.notification {
    width: 16px;
    height: 16px;
    background-color: #B13D58;
    font-size: 10px;
    line-height: 8px;
    color: #FFFFFF;
    position: relative;
    bottom: 36px;
    left: 30px;
    text-decoration: none;
}